@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');



@import "bootstrap";

@import url('../bootstrap-grid.min.css');
@import "buttons";
@import "flag";
@import "core";
@import "header";
@import "preloader";
@import "section";
@import "owl-carousel";
@import "boxes";
@import "modules";

@import "featured-product"; 
@import "latest-blog";
@import "products";  

@import "footer";           
@import "responsive" ;   


body{
    font-weight: 500;
    background: #fff9dc;
    .wrapper{
        max-width: 1920px;
        background: #fff;
        box-shadow: 0 0 30px rgba(0,0,0,0.2);
        margin: 0 auto;
    }

    a, .iconify{
        text-decoration: none;
        transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
        &:hover, &:focus{
            text-decoration: none;
        }
    }

    &.common-home{
        .footer-rewards{
            display: none;
        }
    }

    .row{
        &:before, &:after{
            display: flex;
        }
    }
}


.container {
    max-width: 1204px; 
}

 
.breadcrumb{
        background: none;
        margin: 0;
        a{
            color: #14203D; 
            &:hover, &:focus{ 
                color: #F15623;
                text-decoration: none;
            } 
        }
}  

#top20{
    
    margin-top: 20px;

    @media only screen and (min-width: 768px) {
        column-count: 2;
        column-gap: 5px;
    }
    li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 5px;
        background-color: #f4f4f4;
        padding: 5px;
        break-inside: avoid;

       
        .image{
            flex: 0 0 50px;
            max-width: 50px;
            padding: 5px;
            border: 1px solid #e4e4e4;
            border-radius: 2px;
            margin-right: 15px;
            background: #fff;
        }

        .left{
            display: flex;
            align-items: center;
            margin-right: 20px;
            .title{
                
                h4{
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    margin: 0;
                    a{
                        color: #000;
                    }
                }
            }
        }

        .top20-button{
            font-size: 12px;
            border-radius: 20px;
            span{
                display: none;
            }
            @media only screen and (min-width: 576px) {
                min-width: 130px;
                flex: 0 0 130px;
                span{
                    display: inline-block;
                    margin-left: 5px;
                }
            }
        }
    }
}

#lastorder{
    .products{
        li{
            display: flex;
            align-items: center;
            margin-bottom: 3px;
            .image{
                flex: 0 0 50px;
                max-width: 50px;
                padding: 5px;
                border: 1px solid #e4e4e4;
                border-radius: 2px;
                margin-right: 15px;
                background: #fff;
            }
                
                h4{
                    font-size: 15px;
                    font-weight:500;
                    color: #000;
                    margin: 0;
                    a{
                        color: #000;
                    }
                }
        }
    }
}